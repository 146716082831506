var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Contador de URL's")]),_c('zb-new-url',{directives:[{name:"permissions",rawName:"v-permissions",value:(['CREATE']),expression:"['CREATE']"}]}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.urls,"items-per-page":20,"loading":_vm.loading,"loading-text":"Cargando datos..."},scopedSlots:_vm._u([{key:"header.android",fn:function(ref){
var header = ref.header;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v("fab fa-android")])]}},{key:"header.ios",fn:function(ref){
var header = ref.header;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v("fab fa-apple")])]}},{key:"header.others",fn:function(ref){
var header = ref.header;
return [_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-desktop")])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [(item.url.validUrl)?_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text-caption blue--text d-inline-block text-truncate",staticStyle:{"max-width":"370px"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.url.value)+" ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('a',{attrs:{"href":item.url.value,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Ir a enlace")])])],1)],1)],1)],1):_c('div',[_vm._v(" "+_vm._s(item.url.value)+" ")])]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"permissions",rawName:"v-permissions",value:(['VIEW']),expression:"['VIEW']"}],attrs:{"color":"blue","dark":""},on:{"click":function($event){return _vm.viewDetail(item.url)}}},[_vm._v(" Ver "),_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-plus")])],1)]}},{key:"item.android",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.android)+" clicks ")])]}},{key:"item.ios",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.ios)+" clicks ")])]}},{key:"item.others",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.others)+" clicks ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.total)+" clicks ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }