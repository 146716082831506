import { ServiceFactory } from '@/services/ServiceFactory'
const _urlCounterService = ServiceFactory.get('UrlCounterService')
export default {
  data () {
    return {
      dialog: false,
      postData: {
        bannerUrl: '',
        serviceFrom: ''
      },
      loading: false,
      longUrl: '',
      shortUrl: ''
    }
  },
  // mounted () {
  // },
  methods: {
    async submit () {
      this.loading = true
      try {
        console.log(this.postData)
        const shortenerResp = await _urlCounterService.urlShortener(this.postData)
        console.log(shortenerResp)
        this.longUrl = shortenerResp.longUrl
        this.shortUrl = shortenerResp.shortUrl
        this.loading = false
        this.postData = {
          bannerUrl: '',
          serviceFrom: ''
        }
        this.$notify({
          type: 'success',
          text: 'URL generado guardado correctamente'
        })
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$notify({
          type: 'error',
          text: 'Ocurrió un error al generar la URL'
        })
      }
    },
    copyLong () {
      navigator.clipboard.writeText(this.longUrl)
    },
    copyShort () {
      navigator.clipboard.writeText(this.shortUrl)
    }
  }
}
