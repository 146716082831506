import ZbNewUrl from '../new-url'
import { ServiceFactory } from '@/services/ServiceFactory'
const _urlCounterService = ServiceFactory.get('UrlCounterService')

export default {
  components: {
    ZbNewUrl
  },
  data () {
    return {
      // desserts: [
      //   {
      //     links: 'www.tiktok.com',
      //     android: 159,
      //     ios: 6.0,
      //     total: 24
      //   },
      //   {
      //     links: 'www.reserbar.vip',
      //     android: 237,
      //     ios: 9.0,
      //     total: 37
      //   },
      //   {
      //     links: 'Eclair',
      //     android: 262,
      //     ios: 16.0,
      //     total: 23
      //   },
      //   {
      //     links: 'Jelly bean',
      //     android: 375,
      //     ios: 0.0,
      //     total: 94
      //   },
      //   {
      //     links: 'Lollipop',
      //     android: 392,
      //     ios: 0.2,
      //     total: 98
      //   },
      //   {
      //     links: 'KitKat',
      //     android: 518,
      //     ios: 26.0,
      //     total: 65
      //   }
      // ],
      headers: [
        {
          text: 'Enlaces',
          align: 'start',
          sortable: false,
          value: 'url'
        },
        { text: 'Total Android', align: 'center', sortable: false, value: 'android' },
        { text: 'Total IOS', align: 'center', sortable: false, value: 'ios' },
        { text: 'Total otros', align: 'center', sortable: false, value: 'others' },
        { text: 'Total', align: 'center', sortable: false, value: 'total' },
        { text: 'Opciones', align: 'center', sortable: false, value: 'options' }
      ],
      urls: [],
      loading: false
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      this.loading = true
      try {
        const urlResp = await _urlCounterService.index()
        this.urls = urlResp.url
        this.loading = false
        console.log(urlResp)
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    viewDetail (url) {
      console.log(url)
      // let id = barId
      // this.$store.dispatch('companiesModule/setCompanyData', companyData)
      // this['companiesModule/setCompanyData'](barId)
      // this.$router.push({ name: 'companies-tabs' })
      this.$router.push({ name: 'url-detail', params: { url: url.value } })
    }
  }
}
